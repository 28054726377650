<template>
  <div>
    <div class="item-center" v-if="loading">
      <v-progress-circular
        indeterminate
        size="70"
        color="primary"
      ></v-progress-circular>
    </div>
    <div v-else class="mt-5">
      <v-row align="center" class="ml-0 pb-5">
        <div>
          <span class="register-links-title"> {{ $t("alpha_register") }} </span>
          <ul class="register-links-list pl-0">
            <li v-if="alphaRegisterUrlPt" class="flex-center">
              <div class="flex-center">
                <span class="block mr-2 link-label"
                  >{{ $t("br_investor") }}:</span
                >
                <span>{{ alphaRegisterUrlPt }}</span>
              </div>
              <v-btn
                x-small
                icon
                class="ml-3"
                @click="gs.copy_url_to_clipboard(alphaRegisterUrlPt)"
              >
                <v-icon color="primary">mdi-content-copy</v-icon>
              </v-btn>
            </li>
            <li v-if="alphaRegisterUrlEn" class="flex-center">
              <div class="flex-center">
                <span class="block mr-2 link-label"
                  >{{ $t("us_investor") }}:</span
                >
                <span>{{ alphaRegisterUrlEn }}</span>
              </div>

              <v-btn
                x-small
                icon
                class="ml-3"
                @click="gs.copy_url_to_clipboard(alphaRegisterUrlEn)"
              >
                <v-icon color="primary">mdi-content-copy</v-icon>
              </v-btn>
            </li>
          </ul>
        </div>
      </v-row>

      <!-- custom advisor link -->
      <div class="custom-affiliated-link-container">
        <v-btn
          @click="() => (openDialogCustomAffiliatedLink = true)"
          class="primary"
          >{{ $t("custom_affilated_link") }}</v-btn
        >

        <v-dialog v-model="openDialogCustomAffiliatedLink">
          <v-card class="custom-affiliated-link-dialog-card">
            <h2>{{ $t("edit_affiliated_link") }}</h2>
            <p class="text">
              {{ $t("edit_affiliated_link_text_1") }}
              <span style="font-weight: bold"
                >{{ $t("edit_affilated_link_example") }}
                <span style="color: var(--primary)"
                  >{{ $t("identifier") }}
                </span>
              </span>
              <span>{{ $t("identifier_text") }}</span>
            </p>

            <v-form @submit.stop.prevent="submit()" ref="form">
              <v-text-field
                :rules="[required]"
                dense
                outlined
                v-model="labelValue"
              ></v-text-field>

              <v-card-actions class="pl-0 dxa_modal_actions">
                <v-btn
                  class="dxa_modal_btnSuccess"
                  color="primary"
                  min-width="100"
                  :loading="submitLoading"
                  type="submit"
                  >{{ $t("save") }}</v-btn
                >
                <v-btn
                  class="dxa_modal_btnError"
                  color="red"
                  min-width="100"
                  @click="() => (openDialogCustomAffiliatedLink = false)"
                >
                  {{ $t("cancel") }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import { LanguageTextEnum } from "@/shared/enums/LanguageEnum";

export default {
  name: "ManageUserBanker",
  components: {},

  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    submitLoading: false,
    user: {},
    LanguageTextEnum,
    alphaRegisterUrlPt: null,
    alphaRegisterUrlEn: null,
    openDialogCustomAffiliatedLink: false,
    labelValue: "",
    loading: false,
  }),

  computed: {},
  props: {
    user_prop: Object,
  },

  async created() {
    this.alert_msg = null;
    if (this.user_prop != null) {
      const { Id } = this.user_prop;
      this.user = this.user_prop;
      await this.fetchBankerRegisterLinks(Id);
    }
  },

  methods: {
    async fetchBankerRegisterLinks(bankerId) {
      this.loading = true;
      await this.apiService
        .getRequest(`banker/register-links?bankerId=${bankerId}`)
        .then((resp) => {
          const {
            registerUrlBrazil,
            registerUrlGlobal,
            urlShortnerLabelAlpha,
          } = resp.content;
          this.alphaRegisterUrlPt = registerUrlBrazil;
          this.alphaRegisterUrlEn = registerUrlGlobal;

          if (urlShortnerLabelAlpha) {
            this.labelValue = urlShortnerLabelAlpha;
          }
        })
        .catch((err) => {
          this.$toast.error(this.$t("error_occoured"));
        });
      this.loading = false;
    },
    async submit() {
      const result = this.$refs.form.validate();
      if (this.submitLoading || !result) {
        return;
      }

      this.submitLoading = true;
      const bankerId = this.user.Id;
      // remove empty space
      const sanitizedLabelValue = this.labelValue.replace(/\s+/g, "");
      const globalLabel = `${sanitizedLabelValue}-en`;
      const brLabel = `${sanitizedLabelValue}-br`;

      let payload = {
        globalLabel,
        brLabel,
        alphaLabel: sanitizedLabelValue,
        bankerId,
      };

      await this.apiService
        .postRequest("banker/create-shortner-url", payload)
        .then(async (resp) => {
          this.$toast.success(this.$t("saved"));
          this.openDialogCustomAffiliatedLink = false;
          await this.fetchBankerRegisterLinks(bankerId);
        })
        .catch((err) => {
          let message =
            err.status === 400 ? err.body.message : "error_occoured";

          this.$toast.error(this.$t(message));
        });
      this.submitLoading = false;
    },
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
};
</script>

<style scoped>
.register-links-title {
  font-size: 24px;
  font-weight: 500;
  display: block;
  margin-bottom: 20px;
}

.register-links-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.flex-center {
  display: flex;
  align-items: center;
}

.gap-5 {
  gap: 20px;
}

.gap-3 {
  gap: 12px;
}

.link-label {
  font-size: 16px;
  color: var(--primary);
}

.custom-affiliated-link-container {
  margin-top: 24px;
}

.custom-affiliated-link-dialog-card {
  padding: 20px;
  max-width: 460px;
  margin-left: auto;
  margin-right: auto;
}

.custom-affiliated-link-dialog-card > h2 {
  font-size: 20px;
  text-align: center;
  margin-bottom: 12px;
}

.custom-affiliated-link-dialog-card > p.text {
  font-size: 16px;
  text-align: center;
}

.form-btn {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
}

.item-center {
  display: grid;
  place-items: center;
}
</style>
